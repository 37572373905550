<template>
	<div>
		<dl v-if="detailed && ($api.cart.discount.value || shippingPrice || paymentPrice)">
      <dt>Mezisoučet</dt>
      <dd>
        {{ $api.cart.subtotal ? $price($api.cart.subtotal) : ". . ." }}
      </dd>

      <!-- Percentage discount -> show before shipping -->
      <template v-if="$api.cart.discount.value && $api.cart.discount.type === 'percentage'">
        <dt>Sleva {{ $api.cart.discount.value }} %</dt>
        <dd>{{ $api.cart.subtotal ? "- " + $price(($api.cart.discount.value / 100) * $api.cart.subtotal, 2) : ". . ." }}</dd>
      </template>

      <template v-if="shippingPrice">
        <dt>Doprava</dt>
        <dd>{{ $price(shippingPrice) }}</dd>
      </template>

      <template v-if="paymentPrice">
        <dt>Platba</dt>
        <dd>{{ $price(paymentPrice) }}</dd>
      </template>

      <!-- Absolute value discount -> show after shipping -->
      <template v-if="$api.cart.discount.value && $api.cart.discount.type === 'absolute'">
        <dt>Sleva</dt>
        <dd>- {{ $price($api.cart.discount.value) }}</dd>
      </template>
		</dl>
    <h4>Celková cena</h4>
		<strong>{{ displayTotal === null ? ". . ." : $price(displayTotal, 2) }}</strong>
	</div>
</template>

<script>
	export default {
		props: {
			shippingPrice: {
				default: function() {
					return this.$storage.cart.shipping.price;
				}
			},
			paymentPrice: {
				default: function() {
					return this.$storage.cart.payment.price;
				}
			},
      detailed: {
        type: Boolean,
        default: false,
      },
		},
		watch: {
			displayTotal(value) {
				this.$emit("totalChanged", value);
			}
		},
		computed: {
			displayTotal() {
				if (this.$api.cart.total === null)
					return null;

				return Math.max(this.$api.cart.realTotal + this.shippingPrice + this.paymentPrice, 0);
			}
		},
		name: "TotalPrice"
	}
</script>